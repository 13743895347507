import React from 'react'

const Loader = () => {
  return (
    <div className='' style={{height:"100vh",background :"#fff",position:"relative"}}>
     <div className='loader' style={{position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)"}}>

     </div>
    </div>
  )
}

export default Loader