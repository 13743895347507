import React, { useEffect, useState } from 'react'
import { BASE_URL } from './BaseUrl'
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import InnerHeader from './InnerHeader';
import axios from 'axios';
import { Switch } from '@mui/material';
import Loader from './Loader';
import * as XLSX from "xlsx";

const Students = () => {

    const [excelData, setExcelData] = useState([]);
    function CustomToolbar() {
        return (
          <GridToolbarContainer>
            {/* <GridToolbarExport /> */}
            <GridToolbarFilterButton />
          </GridToolbarContainer>
        );
      }
    const [loading, setLoading] = useState(true)
    const [onlineAdmissions, setOnlineAdmissions] = useState([])
    const label = { inputProps: { 'aria-label': 'Color switch demo' } };

    // const getOnlineAdmissions = async () => {
    //     const response = await fetch(`${BASE_URL}/getAllStudent`, {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         }
    //     })

    //     const data = await response.json();
    //     setOnlineAdmissions(data);
    //     console.log(data);
    //     setLoading(false)
    // }

    async function getOnlineAdmissions(params) {
        axios.get(`${BASE_URL}/getAllStudent` )
        .then((res) =>{
            setOnlineAdmissions(res.data);
            setLoading(false)
        })
    }

    useEffect(() => {
        getOnlineAdmissions();
    }, [])
    const handleUpdate = () => {
        console.log('hehehe')
    }

    const handleswitchchange = (value,Inquiry_Id) =>{
        const newval = value == 0 ? 1 : 0
    
        axios.post(`${BASE_URL}/data_status` , {status : newval, Inquiry_Id : Inquiry_Id, table_name : "Student_Master"})
        .then((res)=>{
            console.log(res)
            getOnlineAdmissions()
            setLoading(false)
        })
     }


    const columns = [
        {
            field: 'index',
            headerName: 'Id',
            type: 'number',
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            filterable: false,
        },
        { field: 'Batch_code', headerName: 'Batch Code', flex: 2 },
        { field: 'Student_Name', headerName: 'Student Name', flex: 2 },
        { field: 'Present_Address', headerName: 'Address', flex: 2 },
        { field: 'Email', headerName: 'Email', flex: 2 },
        { field: 'Present_Mobile', headerName: 'mobile', flex: 2 },
        // { field: 'Qualification', headerName: 'Qualification', flex: 2 },
        { field: 'Status', headerName: 'Status', flex: 2 },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Action',
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={`/admissionform/personalinfo/${params.row.Student_Id}`}><EditIcon style={{ cursor: "pointer" }} /></Link>
                        <Switch {...label} onChange={() => handleswitchchange(params.row.isActive,params.row.id )} defaultChecked={params.row.isActive == 0 ? false : true} color="secondary" />
                    </>
                )
            }
        },
    ];

    const rowsWithIds = onlineAdmissions.map((row, index) => ({ index: index + 1, ...row }));


    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
      
        reader.onload = (event) => {
          const binaryStr = event.target.result;
      
          // Parse the binary string to create a workbook object
          const workbook = XLSX.read(binaryStr, { type: "binary" });
      
          // Get the first sheet name
          const sheetName = workbook.SheetNames[0];
      
          // Get the worksheet from the workbook
          const sheet = workbook.Sheets[sheetName];
      
          // Convert the worksheet to JSON format
          const data = XLSX.utils.sheet_to_json(sheet);
      
          // Set the parsed Excel data (array of objects)
          setExcelData(data);
        };
      
        reader.readAsArrayBuffer(file);

      };
      
    
      const handleImport = async () => {
        const batchSize = 5000;
        for (let i = 0; i < excelData.length; i += batchSize) {
            const batch = excelData.slice(i, i + batchSize);
            await axios.post(`${BASE_URL}/upload-student-excel`, { data: batch });
        }
    };


    return (
        <div className="container-fluid page-body-wrapper col-lg-10">
            <InnerHeader/>
            {loading && <Loader />}
            <div className="main-pannel" style={{display : loading ? "none" : "block"}} >
                <div className="content-wrapper ">
                    <div className="row">
                    {/* <input className="mx-2" type="file" onChange={handleFileUpload}  />
                    <button
                      className="btn btn-success mx-2"
                      onClick={handleImport}
                    >
                      Import 
                    </button> */}
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className='d-flex justify-content-between gap-3' style={{ width: "100%", padding: "10px 0" }}>
                                        <div >
                                            <h4 class="card-title">Student Information</h4>
                                        </div>
                            

                                    </div>

                                    <div>
                                        <DataGrid
                                            rows={rowsWithIds}
                                            columns={columns}
                                            // disableColumnFilter
                                            disableColumnSelector
                                            disableDensitySelector
                                            rowHeight={37}
                                            getRowId={(row) => row.Admission_Id}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: { pageSize: 10, page: 0 },
                                                },
                                            }}
                                            slots={{ toolbar: CustomToolbar }}
                                            slotProps={{
                                                toolbar: {
                                                    showQuickFilter: true,
                                                },
                                            }}
                                        />
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
        </div>
    )
}

export default Students